.custom-select-container {
  position: absolute;
  display: inline-block;
  padding-inline: 5px;
}

.custom-select-button {
  cursor: pointer;
}

.custom-select-button:hover > svg {
  transform: scale(1.5);
}

.custom-select-options {
  position: absolute;
  top: 100%;
  min-width: 80px;
  border: 1px solid #c5c5c5;
  border-radius: 2px;
  background-color: #ffffff;
  z-index: 1;
  padding-block: 5px;
}

.custom-select-options option {
  padding-block: 2px;
  padding-inline: 19px;
  font-size: 12px;
  cursor: pointer;
}

.custom-select-options option.selected {
  background-color: #ecf9fe;
}

.custom-select-options option:hover {
  background-color: #f0f0f0;
}
